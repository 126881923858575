<template>
  <form>
    <!-- user intents: checklist (simple) -->
    <v-card
      v-show="showUserIntentsChecklistCard"
      class="mt-4"
    >
      <v-progress-linear
        absolute
        top
        height="2"
        color="primary"
      />
      <v-card-title class="widget-card-title">
        {{ $t('QUESTION_RELATED_TO_USER_INTENT') }}
      </v-card-title>
      <v-card-text>
        <template v-for="(item, i) in computedUserIntentOptionsList">
          <v-checkbox
            :id="`user-intent-${screenIdentifier}-${i}`"
            :key="`priority-${i}`"
            v-model="internalUserResponses.userIntents"
            class="mt-0 mb-0"
            dense
            :label="item.label"
            :value="item.value"
            @change="updateResponse('userIntents', internalUserResponses.userIntents)"
          >
            <template slot="append">
              <v-icon
                v-show="item.popular"
                small
                color="grey"
              >
                mdi-heart
              </v-icon>
            </template>
          </v-checkbox>
        </template>

        <v-divider />
        <div
          class="grey--text text-darken-1 caption mt-2 ml-2 text-right"
        >
          {{ $t('POPULAR_CHOICES_CAPTION_TEXT') }}
          <v-icon
            small
            color="grey"
          >
            mdi-heart
          </v-icon>
        </div>
      </v-card-text>
    </v-card>

    <!-- user intents: checklist w. adaptive images -->
    <v-card
      v-show="showPointOfViewChecklistCard"
      class="mt-4"
    >
      <v-progress-linear
        absolute
        top
        height="2"
        color="primary"
      />
      <v-card-title class="widget-card-title">
        {{ $t('QUESTION_RELATED_TO_USER_INTENT_RELATED_TO_SPECIFIC_POINT_OF_VIEWS') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('NR_OF_IMAGES_TO_TAKE_TEXT') }}: {{ nrImagesToTake ? nrImagesToTake : '-' }}
      </v-card-subtitle>
      <v-list>
        <template v-for="(item, i) in computedPointOfViewOptionsList">
          <v-divider
            v-if="i !== 0"
            :key="`${i}-divider`"
          />

          <v-list-item
            :key="`${i}-${item.text}`"
          >
            <v-list-item-action>
              <v-checkbox
                :id="`user-intent-${i}`"
                :key="`priority-${i}`"
                v-model="internalUserResponses.pointOfViews"
                class="mt-0 mb-0"
                dense
                :value="item.value"
                @change="
                  updateResponse('pointOfViews', internalUserResponses.pointOfViews),
                  calcImagesToTakeViaUserIntent(),
                  animateNrImagesToTakeHasChanged()
                "
              >
                <template v-slot:label>
                  <div
                    :class="internalUserResponses.pointOfViews.includes(item.value) ? 'primary--text' : 'grey--text text--darken-1'"
                    class="ml-4"
                    v-text="item.label"
                  />
                </template>
              </v-checkbox>
            </v-list-item-action>

            <v-spacer />

            <v-scroll-x-transition>
              <v-icon
                v-if="item.popular"
                small
                color="grey"
              >
                mdi-heart
              </v-icon>
            </v-scroll-x-transition>
          </v-list-item>
        </template>
      </v-list>
    </v-card>

    <!-- primary goal: text field -->

    <v-card class="mt-4">
      <v-progress-linear
        absolute
        top
        height="2"
        color="primary"
      />
      <v-card-title class="widget-card-title">
        {{ $t('HEADER_FOR_QUESTION_RELATED_TO_PRIMARY_GOAL') }}
      </v-card-title>
      <v-card-text>
        <validation-provider
          id="primary-goal"
          v-slot="{ errors }"
          name="Primary goal"
        >
          <v-textarea
            v-model="internalUserResponses.primaryGoal"
            :placeholder="$t('HINT_FOR_QUESTION_RELATED_TO_PRIMARY_GOAL')"
            :error-messages="errors"
            filled
            color="secondary"
            validate-on-blur
            @change="updateResponse('primaryGoal', internalUserResponses.primaryGoal)"
          />
        </validation-provider>
      </v-card-text>
    </v-card>

    <!-- base user info -->
    <v-card class="mt-4">
      <v-progress-linear
        absolute
        top
        height="2"
        color="primary"
      />
      <v-card-title class="widget-card-title">
        {{ $t('TAB_HEADING_BASE_INFO') }}
      </v-card-title>
      <v-card-text>
        <validation-provider
          id="name-field"
          v-slot="{ errors }"
          rules="required"
          name="Name"
        >
          <v-text-field
            v-model="internalUserResponses.name"
            :error-messages="errors"
            color="secondary"
            type="text"
            :label="$t('QUESTION_RELATED_TO_NAME')"
            prepend-icon="mdi-face"
            validate-on-blur
            @change="updateResponse('name', internalUserResponses.name)"
          />
        </validation-provider>
        <validation-provider
          id="email-field"
          v-slot="{ errors }"
          rules="required"
          name="Email"
        >
          <v-text-field
            v-model="internalUserResponses.email"
            :error-messages="errors"
            color="secondary"
            :label="$t('QUESTION_RELATED_TO_EMAIL')"
            autocomplete="email"
            type="email"
            prepend-icon="mdi-email"
            validate-on-blur
            @change="updateResponse('email', internalUserResponses.email), onEmailInputChange(internalUserResponses.email)"
          />
        </validation-provider>
        <validation-provider
          id="phone-number-field"
          v-slot="{ errors }"
          name="Phone number"
        >
          <v-text-field
            v-model="internalUserResponses.phone"
            :error-messages="errors"
            color="secondary"
            type="text"
            :label="$t('QUESTION_RELATED_TO_PHONE')"
            prepend-icon="mdi-phone"
            validate-on-blur
            @change="updateResponse('phone', internalUserResponses.phone)"
          />
        </validation-provider>
        <validation-provider
          id="year-field"
          v-slot="{ errors }"
          rules="required"
          name="Age"
        >
          <v-text-field
            v-model="internalUserResponses.year"
            :error-messages="errors"
            color="secondary"
            type="text"
            :label="$t('QUESTION_RELATED_TO_AGE_IN_YEARS')"
            prepend-icon="mdi-calendar"
            validate-on-blur
            @change="updateResponse('year', internalUserResponses.year)"
          />
        </validation-provider>
      </v-card-text>
    </v-card>

    <!-- base user info -->
    <v-card
      v-show="showSocialSecurityInfoCard"
      class="mt-4"
    >
      <v-progress-linear
        absolute
        top
        height="2"
        color="primary"
      />
      <v-card-title class="widget-card-title">
        Dit ID i vores system.
      </v-card-title>
      <v-card-text>
        <validation-provider
          id="social-security-field"
          v-slot="{ errors }"
          rules="required"
          name="Id"
        >
          <v-text-field
            v-model="internalUserResponses.socialSecurityNumber"
            :error-messages="errors"
            color="secondary"
            :label="$t('QUESTION_RELATED_TO_SOCIAL_SECURITY_NUMBER')"
            prepend-inner-icon="mdi-lock"
            :append-icon="showSocialSecurityNumber ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showSocialSecurityNumber ? 'text' : 'password'"
            validate-on-blur
            @click:append="showSocialSecurityNumber = !showSocialSecurityNumber"
            @change="updateResponse('socialSecurityNumber', internalUserResponses.socialSecurityNumber)"
          />
        </validation-provider>
      </v-card-text>
    </v-card>

    <!-- clinic list: checklist (simple) -->
    <div v-if="showClinicLocationsCard">
      <v-card
        class="mt-4"
      >
        <v-progress-linear
          absolute
          top
          height="2"
          color="primary"
        />
        <v-card-title class="widget-card-title">
          {{ $t('HEADER_FOR_QUESTION_RELATED_TO_CLINIC_LOCATION') }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedClinic.title"
            :items="clinicsAsFlatList"
            @change="updateClinicLocation(selectedClinic.title)"
          />
        </v-card-text>
      </v-card>
    </div>

    <!-- pre-conceived treatment options: checklist -->

    <!-- insurance company: radio buttons -->

    <!-- user intents: text field -->

    <!-- time preference: radio buttons -->

    <!-- top priorities: checklist -->

    <!-- top priorities: text field -->
  </form>
</template>

<script>
  // NOTE: The Vuex store is loaded from FunnelWidget. It is
  //   accessed via 'currentPage'. If the store is loaded in
  //   this component it will reset the store.
  import { mapMutations, mapGetters, mapActions } from 'vuex'

  import submitApiServices from '@/views/widgets/services/submit_endpoints'

  export default {
    name: 'FunnelInformation',

    props: {
      showBaseInfoCard: {
        type: Boolean,
        required: true,
      },
      showBaseInfoCardTimeOut: {
        type: Number,
        default: 0,
      },
      showPrimaryGoalCard: {
        type: Boolean,
        required: true,
      },
      showPrimaryGoalCardTimeOut: {
        type: Number,
        default: 0,
      },
      showSocialSecurityInfoCard: {
        type: Boolean,
        required: true,
      },
      showClinicLocationsCard: {
        type: Boolean,
        default: false,
      },
      nrImagesToTake: {
        type: Number,
        default: 5,
      },
      activeUserIntent: {
        type: Object,
        default () {
          return {}
        },
      },
      showUserIntentsChecklistCard: {
        type: Boolean,
        required: true,
      },
      showPointOfViewChecklistCard: {
        type: Boolean,
        required: true,
      },
      assessmentUuid: {
        type: String,
        required: true,
      },
      screenIdentifier: {
        type: String,
        required: true,
      },
      widgetType: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      // Conditional rendering variable: To render follow-ups if relevant
      primaryGoal: '',
      internalUserResponses: {
        userIntents: [],
        pointOfViews: [],
        year: '',
        name: '',
        primaryGoal: '',
        phone: '',
        email: '',
        socialSecurityNumber: '',
      },

      selectedClinic: {
        title: '',
        uuid: '',
      },
      clinicAsObjectList: [],
      clinicsAsFlatList: [],

      // UX state
      showSignOfNrImagesToTakeHasChanged: false,

      showSocialSecurityNumber: false,
    }),

    computed: {
      ...mapGetters({
        userResponses: 'currentPage/userResponses',
        userIntentOptionsList: 'currentPage/userIntentOptionsList',
        priorityOptionsList: 'currentPage/priorityOptionsList',
      }),
      computedUserIntentOptionsList () {
        const clinicsChosenIntents = this.$t('PRIORITY_USER_INTENTS_FOR_CLINIC')
        const _intents = []
        let i = 0
        for (i in this.userIntentOptionsList) {
          const isPriority = clinicsChosenIntents.includes(this.userIntentOptionsList[i].value)
          const isDefault = this.userIntentOptionsList[i].default
          if (isPriority || isDefault) {
            _intents.push(this.userIntentOptionsList[i])
          }
        }
        return _intents.map(this.mapUserResponseOptionsByLabel)
      },
      computedPointOfViewOptionsList () {
        const clinicsChosenIntents = this.$t('POINT_OF_VIEW_LIST')
        const _intents = []
        let i = 0
        for (i in this.userIntentOptionsList) {
          const isPriority = clinicsChosenIntents.includes(this.userIntentOptionsList[i].value)
          const isDefault = this.userIntentOptionsList[i].default
          if (isPriority || isDefault) {
            _intents.push(this.userIntentOptionsList[i])
          }
        }
        return _intents.map(this.mapUserResponseOptionsByLabel)
      },
      includePointOfViewList () {
        let _includePointOfViewList = false
        if (this.widgetType === 'emergency_widget') {
          _includePointOfViewList = true
        }
        return _includePointOfViewList
      },
    },

    async mounted () {
      setTimeout(() => (
        this.isPrimaryGoalCardVisible = this.showPrimaryGoalCard
      ), this.showPrimaryGoalCardTimeOut)

      // TODO: Only do this operation, when relevant
      if (this.showClinicLocationsCard) {
        await this.populateClinicListFromDynamicModule()
      }
    },

    methods: {
      ...mapActions({
        calcImagesToTakeViaUserIntent: 'currentPage/calcImagesToTakeViaUserIntent',
      }),
      ...mapMutations({
        setUserResponse: 'currentPage/setUserResponse',
        setExplicitClinic: 'currentPage/setExplicitClinic',
      }),
      async populateClinicListFromDynamicModule () {
        // TODO: Make this dynamically loaded, or get from API.
        // const _path = await this.$t('ENTERPRISE_CLINIC_MODULE_PATH_TO_CLINIC_AND_GROUPS')
        const clinicAsObjectList = await require('@/locales/enterprise_providers/pacificsmilesdental/nsw_clinics.json')
        const flatList = []
        let suggestedClinic = null
        let i = 0
        for (i in clinicAsObjectList) {
          if (Number(i) === 0) {
            // TODO: This could become more intelligent, e.g. based on a
            //   rough localization estimation from IP address via an API.
            suggestedClinic = clinicAsObjectList[i]
          }
          flatList.push(clinicAsObjectList[i].title)
        }
        this.clinicAsObjectList = clinicAsObjectList
        this.clinicsAsFlatList = flatList
        this.selectedClinic = {
          title: suggestedClinic.title,
          uuid: suggestedClinic.uuid,
        }
      },

      updateClinicLocation (title) {
        let objectClinicIncludingGroupReferences
        let i = 0
        for (i in this.clinicAsObjectList) {
          const _clinic = this.clinicAsObjectList[i]
          if (_clinic.title === title) {
            objectClinicIncludingGroupReferences = _clinic
            break
          }
        }
        const payload = objectClinicIncludingGroupReferences
        this.setExplicitClinic(payload)
      },

      updateResponse (topic, response) {
        const payload = { topic: topic, response: response }
        this.setUserResponse(payload)
      },
      mapUserResponseOptionsByLabel (item) {
        return {
          ...item,
          label: this.$t(item.label),
        }
      },
      mapUserResponseOptionsByText (item) {
        return {
          ...item,
          text: this.$t(item.text),
        }
      },
      isObjectEmpty (obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
      },
      animateNrImagesToTakeHasChanged (timeout = 1000) {
        const that = this
        that.showSignOfNrImagesToTakeHasChanged = true
        setTimeout(() => (that.showSignOfNrImagesToTakeHasChanged = false), timeout)
      },

      async onEmailInputChange (email) {
        await submitApiServices.submitEmail({ assessmentUuid: this.assessmentUuid, email: email })
        const isInternalAdentEmail = this._isInternalAdentEmail(email)
        try {
          window.fbq('trackCustom', 'UserEmailSubmitted', { assessmentUuid: this.assessmentUuid, isInternalAdentEmail: isInternalAdentEmail })
        } catch (e) {
          // Add airbrake
        }
      },

      _isInternalAdentEmail (email) {
        /** Used to filter out internal testing */
        return email.includes('@adent.dk')
      },
    },
  }
</script>

<style lang="sass">
  .widget-display-2
    font-size: 18px !important
</style>
